.ev-quote-calculator {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

.ev-quote-calculator.full-page {
  margin: 0 auto;
  min-height: calc(100vh - 4rem);
  border-radius: 0;
  display: flex;
  flex-direction: column;
}

.quote-progress {
  margin-bottom: 2rem;
}

.progress-bar {
  height: 4px;
  background: #e9ecef;
  border-radius: 2px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: #1e3c72;
  transition: width 0.3s ease;
}

.steps {
  display: flex;
  justify-content: space-between;
  color: #6c757d;
  font-size: 0.9rem;
}

.steps span {
  position: relative;
  padding-top: 8px;
}

.steps span.active {
  color: #1e3c72;
  font-weight: 600;
}

.steps span::before {
  content: '';
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background: #e9ecef;
  border-radius: 50%;
}

.steps span.active::before {
  background: #1e3c72;
}

.quote-step {
  min-height: 400px;
  animation: fadeIn 0.3s ease;
}

.quote-step h3 {
  color: #1e3c72;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  text-align: center;
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

@media (max-width: 1200px) {
  .options-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .options-grid {
    grid-template-columns: 1fr;
  }
}

.option-card {
  padding: 1.5rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.option-card:hover {
  border-color: #1e3c72;
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.option-card.selected {
  border-color: #1e3c72;
  background: #f8f9ff;
}

.option-card h4 {
  color: #1e3c72;
  margin: 0;
  font-size: 1.2rem;
  min-height: 2.4rem;
  display: flex;
  align-items: center;
}

.option-card .charger-type {
  color: #6c757d;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  text-transform: capitalize;
  min-height: 1.8rem;
  display: flex;
  align-items: center;
}

.option-card .price {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2a5298;
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid #e9ecef;
  text-align: center;
}

.option-card ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0 0;
  flex-grow: 1;
}

.option-card li {
  padding: 0.5rem 0;
  color: #495057;
  display: flex;
  align-items: flex-start;
  font-size: 0.95rem;
  line-height: 1.4;
}

.option-card li::before {
  content: "✓";
  color: #1e3c72;
  margin-right: 0.5rem;
  font-weight: bold;
  flex-shrink: 0;
}

/* Ensure cards maintain equal height on different screen sizes */
@media (max-width: 1200px) {
  .option-card h4 {
    min-height: auto;
  }
  
  .option-card .charger-type {
    min-height: auto;
  }
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 1rem;
  color: #495057;
  font-weight: 500;
  font-size: 1.1rem;
}

.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  color: #495057;
  transition: border-color 0.3s ease;
  background: white;
}

.form-group select:focus {
  outline: none;
  border-color: #1e3c72;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.radio-label:hover {
  border-color: #1e3c72;
  background: #f8f9ff;
}

.radio-label input[type="radio"] {
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  accent-color: #1e3c72;
}

.price-tag {
  margin-left: auto;
  color: #2a5298;
  font-weight: 600;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.checkbox-label:hover {
  border-color: #1e3c72;
  background: #f8f9ff;
}

.checkbox-label input[type="checkbox"] {
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  accent-color: #1e3c72;
}

.quote-result {
  text-align: center;
}

.quote-breakdown {
  max-width: 400px;
  margin: 2rem auto;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
}

.quote-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #e9ecef;
  font-size: 1.1rem;
}

.quote-item:last-child {
  border-bottom: none;
}

.quote-item.grant {
  color: #28a745;
}

.quote-item.total {
  font-weight: 600;
  font-size: 1.3rem;
  color: #1e3c72;
}

.quote-actions {
  margin: 2rem 0;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.quote-notes {
  color: #6c757d;
  font-size: 0.875rem;
  max-width: 600px;
  margin: 2rem auto 0;
}

.quote-notes p {
  margin: 0.5rem 0;
}

.quote-navigation {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  position: sticky;
  bottom: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.quote-navigation.hidden {
  opacity: 0;
  pointer-events: none;
}

.primary-button,
.secondary-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
}

.primary-button {
  background: #1e3c72;
  color: white;
  border: none;
}

.primary-button:hover {
  background: #2a5298;
}

.primary-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.secondary-button {
  background: white;
  color: #1e3c72;
  border: 2px solid #1e3c72;
}

.secondary-button:hover {
  background: #f8f9ff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .ev-quote-calculator {
    padding: 1rem;
    margin: 1rem;
  }

  .steps {
    font-size: 0.8rem;
  }

  .quote-step {
    min-height: 300px;
  }

  .quote-actions {
    flex-direction: column;
  }

  .primary-button,
  .secondary-button {
    width: 100%;
  }

  .radio-label,
  .checkbox-label {
    padding: 0.75rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .option-card,
  .progress,
  .primary-button,
  .secondary-button {
    transition: none;
  }

  .quote-step {
    animation: none;
  }
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  color: #495057;
  transition: border-color 0.3s ease;
  background: white;
  margin-top: 0.5rem;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #1e3c72;
}

.file-upload {
  margin-top: 0.5rem;
}

.file-input {
  width: 100%;
  padding: 1rem;
  border: 2px dashed #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-input:hover {
  border-color: #1e3c72;
  background: #f8f9ff;
}

.file-help {
  color: #6c757d;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.quote-confirmation {
  text-align: center;
  margin: 2rem 0;
  padding: 2rem;
  background: #f8f9ff;
  border-radius: 12px;
}

.quote-confirmation h4 {
  color: #1e3c72;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.email-highlight {
  color: #1e3c72;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 1rem 0;
  padding: 0.5rem;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
}

.octopus-info {
  background-color: #f5f9ff;
  border: 1px solid #e0e9ff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.octopus-info p {
  margin: 0;
  color: #333;
  font-size: 0.95rem;
}

.octopus-info a {
  color: #4361ee;
  text-decoration: none;
  font-weight: 500;
}

.octopus-info a:hover {
  text-decoration: underline;
}

.company-details {
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.company-details p {
  margin-bottom: 0.75rem;
}

.company-details a {
  color: #0066cc;
  text-decoration: none;
}

.company-details a:hover {
  text-decoration: underline;
}

.copyright {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
  font-size: 0.9rem;
  color: #6c757d;
  text-align: center;
} 